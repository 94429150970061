const ADD_TOAST = 'ADD_TOAST';
const DELETE_TOAST = 'DELETE_TOAST';

export const addToast = (toast) => {
  return {
    type: ADD_TOAST,
    payload: toast
  };
};

export const deleteToast = (toastId) => {
  return {
    type: DELETE_TOAST,
    payload: toastId
  };
};

const initialState = {
  toasts: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload]
      };
    case DELETE_TOAST:
      const toastId = action.payload;
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== toastId)
      };
    default:
      return state;
  }
}
