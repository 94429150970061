const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const selectCategory = (selectedCategory) => {
  return {
    type: SELECT_CATEGORY,
    payload: selectedCategory
  };
};

const initialState = {
  category: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    default:
      return state;
  }
}
