export const DEFAULT_CATEGORY = 'restaurant';

export const CATEGORIES = {
  supermarket: {
    id: 1,
    displayName: 'Grocery'
  },
  restaurant: {
    id: 2,
    displayName: 'Restaurants'
  },
  park: {
    id: 3,
    displayName: 'Parks'
  },
  shopping: {
    id: 4,
    displayName: 'Shopping',
    types: [
      { type: 'shopping_mall', displayName: 'Shopping Mall' },
      { type: 'clothing_store', displayName: 'Clothing Store' },
      { type: 'convenience_store', displayName: 'Convenience Store' },
      { type: 'department_store', displayName: 'Department Store' },
      { type: 'jewelry_store', displayName: 'Jewelry Store' }
    ]
  },
  drugstore: {
    id: 5,
    displayName: 'Pharmacy'
  }
};

export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export const CARD_BACKDROP_TYPES = {
  PlaceUpdateSuccess: 'PlaceUpdateSuccess',
  PlaceShare: 'PlaceShare',
  PlaceNotify: 'PlaceNotify'
};

export const DUMMY_PLACE = {
  business_status: 'OPERATIONAL',
  // formatted_address: 'Next to Sughad Toll plaza, Sughad, Ahmedabad, Gujarat 382428',
  formatted_address: '3350 Zanker Rd #30, San Jose, CA 95134, United States',
  geometry: {
    location: {
      lat: 23.114886,
      lng: 72.6431261
    },
    viewport: {
      northeast: {
        lat: 23.11632567989272,
        lng: 72.64447512989273
      },
      southwest: {
        lat: 23.11362602010728,
        lng: 72.64177547010729
      }
    }
  },
  icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
  // name: 'Indian Restaurant',
  name: 'THIS IS TEST',
  opening_hours: {
    open_now: true
  },
  photos: [
    {
      height: 4160,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/109913212096534020948">Saikat Chakraborty</a>'
      ],
      photo_reference:
        'CmRaAAAA-_lwwTlP4P4nhtzELlW2v2FjPXgbyJky1F-p2C_m-EKVT8184_oGUobo3qA0ybE_5Y-HkpV1svMew4tnc5aFH0v-NKUiVWPg6OII_KjDq_cg5_k9zJsDMGu8EkFGUfHQEhBBo3g2vSJGAYqdpz9RuB7jGhSXdE_aOeqYHhkMpypudW4sfCV3MQ',
      width: 3120
    }
  ],
  place_id: 'ChIJXZ93Q6KBXjkR7HDVOOsQdSE',
  plus_code: {
    compound_code: '4J7V+X7 Sughad, Ahmedabad, Gujarat',
    global_code: '7JMJ4J7V+X7'
  },
  price_level: 2,
  rating: 5,
  reference: 'ChIJXZ93Q6KBXjkR7HDVOOsQdSE',
  types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
  user_ratings_total: 12,
  id: '610ff52f-a9dc-4b2f-8e99-43da4a039fd7',
  vendorId: '9b9a78f155c8bfaaf9acfa69fbecfa3bdbb928cb',
  airQualityIndex: 40,

  // additionalData: []
  totalOverallSafetyRatings: 5,
  averageOverallSafetyRatings: 0.6,
  additionalData: [
    {
      cleanlinessStatus: 'Very Clean',
      crowdStatus: null,
      lastUpdatedAt: 1594810892181,
      peopleWearingMasks: 'Yes',
      socialDistancing: false,
      temperatureCheck: false,
      waitTime: 17,
      dineInService: true,
      outdoorSeating: false,
      curbsidePickup: true,
      noContactDelivery: true,
      overallSafety: 1
    }
  ]
};
