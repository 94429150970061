import { combineReducers } from 'redux';
import location from './location';
import category from './category';
import search from './search';
import place from './place';
import toast from './toast';

export default combineReducers({
  location,
  category,
  search,
  place,
  toast
});
