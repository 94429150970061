import { makeAPICall } from '../../common/api';

const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION';
const FETCHING_SEARCH_SUGGESTIONS = 'FETCHING_SEARCH_SUGGESTIONS';
const FETCH_SEARCH_SUGGESTIONS_SUCCESS = 'FETCH_SEARCH_SUGGESTIONS_SUCCESS';
const FETCH_SEARCH_SUGGESTIONS_ERROR = 'FETCH_SEARCH_SUGGESTIONS_ERROR';

export const setSearchQuery = (query) => {
  return {
    type: SET_SEARCH_QUERY,
    payload: query
  };
};

export const setSearchLocation = (location) => {
  return {
    type: SET_SEARCH_LOCATION,
    payload: location
  };
};

export const fetchingSearchSuggestions = (isFetching) => {
  return {
    type: FETCHING_SEARCH_SUGGESTIONS,
    payload: isFetching
  };
};

export const fetchSearchSuggestionsSuccess = (suggestions) => {
  return {
    type: FETCH_SEARCH_SUGGESTIONS_SUCCESS,
    payload: suggestions
  };
};

export const fetchSearchSuggestionsError = (err) => {
  return {
    type: FETCH_SEARCH_SUGGESTIONS_ERROR,
    payload: err
  };
};

export const fetchSearchSuggestions = (query, sessionToken) => async (dispatch, getState) => {
  const userLocation = getState().location.location;
  let url =
    process.env.REACT_APP_BASE_API_URL +
    'autocomplete/get-suggestions?' +
    `input=${query}&sessionToken=${sessionToken}`;

  if (userLocation) {
    url = url + `&latitude=${userLocation.lat}&longitude=${userLocation.lon}`;
  }

  dispatch(fetchingSearchSuggestions(true));

  try {
    const response = await makeAPICall({ method: 'get', url: url });

    dispatch(fetchSearchSuggestionsSuccess(response.suggestions));
    dispatch(fetchingSearchSuggestions(false));
  } catch (err) {
    dispatch(fetchSearchSuggestionsError(err));
    dispatch(fetchingSearchSuggestions(false));
  }
};

const initialState = {
  searchQuery: '',
  searchLocation: '',
  fetchingSearchSuggestions: false,
  searchSuggestions: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload
      };
    case SET_SEARCH_LOCATION:
      return {
        ...state,
        searchLocation: action.payload
      };
    case FETCHING_SEARCH_SUGGESTIONS:
      return {
        ...state,
        fetchingSearchSuggestions: action.payload
      };
    case FETCH_SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        searchSuggestions: action.payload
      };
    case FETCH_SEARCH_SUGGESTIONS_ERROR:
      return {
        ...state,
        searchSuggestions: action.payload
      };
    default:
      return state;
  }
}
