import TagManager from 'react-gtm-module';
import shortid from 'shortid';

export const sendEventToGoogleAnalytics = (event) => {
  const args = {
    dataLayer: {
      event: event
    }
  };

  TagManager.dataLayer(args);
};

export const createToast = (type, message) => {
  return {
    id: shortid(),
    type: type,
    message: message
  };
};

export const isLauktiDomain = () => {
  return window.location.hostname.includes('laukti');
};

export const getFormattedAddress = (address) => {
  if (!address || !address.length) {
    return '';
  } else {
    const splitAddress = address.split(',');
    splitAddress.pop();
    return splitAddress.join(', ');
  }
};
