import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '0.6875rem',
    lineHeight: '0.875rem',
    textTransform: 'uppercase'
  },
  labelDark: {
    color: '#5F6A70'
  },
  labelFocusedDark: {
    color: '#5F6A70'
  },
  labelRootDark: {
    '&$labelFocusedDark': {
      color: '#5F6A70'
    }
  },
  labelLight: {
    color: '#F4F6F8'
  },
  labelFocusedLight: {
    color: '#F4F6F8'
  },
  labelRootLight: {
    '&$labelFocusedLight': {
      color: '#F4F6F8'
    }
  },
  field: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #A3A9AD',
    borderRadius: '5px',
    marginTop: '0.3rem',
    padding: '0.1rem 0.5rem'
  },
  input: {
    '&::placeholder': {
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.875rem'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.625rem'
      }
    }
  },
  menuItem: {
    color: '#002134',
    fontSize: '0.875rem',
    lineHeight: '1.125rem'
  }
}));

export default useStyles;
