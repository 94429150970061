import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormField from '../FormField/FormField';
import { makeAPICall } from '../../common/api';
import { setLocation as setLocationInStore, setReverseGeocodeLocation } from '../../redux/modules/location';
import { fetchPlaces } from '../../redux/modules/place';
import { addToast } from '../../redux/modules/toast';
import { createToast } from '../../common/helpers';
import { TOAST_TYPES } from '../../common/constants';

const CurrentLocationInputField = (props, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [location, setLocation] = useState('');
  const searchQuery = useSelector((state) => state.search.searchQuery);

  const handleChange = (e) => {
    const value = e.target.value.trim();
    setLocation(value);

    if (props.handleInputChange) {
      props.handleInputChange(value);
    }
  };

  const setNewLocation = async () => {
    setLocation('');

    if (props.settingNewLocation) {
      props.settingNewLocation();
    }

    const url = process.env.REACT_APP_BASE_API_URL + `location/geocode?location=${location}`;

    try {
      const response = await makeAPICall({ method: 'get', url: url });

      dispatch(setLocationInStore(response.geocode));
      dispatch(setReverseGeocodeLocation(response.formattedAddress));

      if (searchQuery.trim().length) {
        history.push('/places/' + searchQuery);
        dispatch(fetchPlaces());
      }
    } catch (err) {
      dispatch(
        addToast(createToast(TOAST_TYPES.ERROR, 'Something went wrong while updating your location. Please try again.'))
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setNewLocation();
    }
  };

  useImperativeHandle(ref, () => ({
    setNewLocation: setNewLocation
  }));

  return (
    <FormField
      labelColor="dark"
      type="text"
      label="Location"
      name="location"
      placeholder="City, State OR Zip Code"
      onChangeHandler={handleChange}
      handleKeyDown={handleKeyDown}
    />
  );
};

CurrentLocationInputField.propTypes = {
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]).isRequired,
  handleInputChange: PropTypes.func,
  settingNewLocation: PropTypes.func
};

export default forwardRef(CurrentLocationInputField);
