import { makeAPICall } from '../../common/api';

const FETCHING_USER_LOCATION = 'FETCHING_USER_LOCATION';
const FETCH_USER_LOCATION_SUCCESS = 'FETCH_USER_LOCATION_SUCCESS';
const FETCH_USER_LOCATION_ERROR = 'FETCH_USER_LOCATION_ERROR';
const FETCHING_REVERSE_GEOCODE_LOCATION = 'FETCHING_REVERSE_GEOCODE_LOCATION';
const FETCH_REVERSE_GEOCODE_LOCATION_SUCCESS = 'FETCH_REVERSE_GEOCODE_LOCATION_SUCCESS';
const FETCH_REVERSE_GEOCODE_LOCATION_ERROR = 'FETCH_REVERSE_GEOCODE_LOCATION_ERROR';
const SET_REVERSE_GEOCODE_LOCATION = 'SET_REVERSE_GEOCODE_LOCATION';
const SET_LOCATION = 'SET_LOCATION';

export const fetchingUserLocation = (isFetching) => {
  return {
    type: FETCHING_USER_LOCATION,
    payload: isFetching
  };
};

export const fetchUserLocationSuccess = (coords) => {
  return {
    type: FETCH_USER_LOCATION_SUCCESS,
    payload: {
      lat: coords.latitude,
      lon: coords.longitude
    }
  };
};

export const fetchUserLocationError = (err) => {
  return {
    type: FETCH_USER_LOCATION_ERROR,
    payload: err
  };
};

export const fetchingReverseGeocodeLocation = (isFetching) => {
  return {
    type: FETCHING_REVERSE_GEOCODE_LOCATION,
    payload: isFetching
  };
};

export const fetchReverseGeocodeLocationSuccess = () => {
  return {
    type: FETCH_REVERSE_GEOCODE_LOCATION_SUCCESS
  };
};

export const fetchReverseGeocodeLocationError = () => {
  return {
    type: FETCH_REVERSE_GEOCODE_LOCATION_ERROR
  };
};

export const setReverseGeocodeLocation = (location) => {
  return {
    type: SET_REVERSE_GEOCODE_LOCATION,
    payload: location
  };
};

export const setLocation = (coords) => {
  return {
    type: SET_LOCATION,
    payload: coords
      ? {
          lat: coords.latitude,
          lon: coords.longitude
        }
      : null
  };
};

export const fetchReverseGeoCodedLocation = () => async (dispatch, getState) => {
  const userLocation = getState().location.location;
  const latitude = userLocation.lat;
  const longitude = userLocation.lon;
  const url =
    process.env.REACT_APP_BASE_API_URL + `location/reverse-geocode?latitude=${latitude}&longitude=${longitude}`;

  dispatch(fetchingReverseGeocodeLocation(true));

  try {
    const response = await makeAPICall({ method: 'get', url: url });

    dispatch(fetchingReverseGeocodeLocation(false));
    dispatch(fetchReverseGeocodeLocationSuccess());
    dispatch(setReverseGeocodeLocation(response.cityState));
  } catch (err) {
    dispatch(fetchReverseGeocodeLocationError());
    dispatch(setReverseGeocodeLocation(''));
  }
};

export const fetchUserLocation = () => (dispatch) => {
  dispatch(fetchingUserLocation(true));

  const handleSuccess = (pos) => {
    dispatch(fetchUserLocationSuccess(pos.coords));
    dispatch(fetchingUserLocation(false));
    dispatch(fetchReverseGeoCodedLocation());
  };

  const handleError = (err) => {
    console.log('Error: ', err);
    dispatch(fetchUserLocationError(err));
    dispatch(fetchingUserLocation(false));
  };

  window.navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
    enableHighAccuracy: true,
    timeout: 15000,
    maximumAge: 0
  });
};

const initialState = {
  fetchingUserLocation: false,
  fetchingReverseGeocodeLocation: false,
  location: null,
  reverseGeocodeLocation: '',
  fetchUserLocationFailed: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_USER_LOCATION:
      return {
        ...state,
        fetchingUserLocation: action.payload
      };
    case FETCH_USER_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.payload
      };
    case FETCH_USER_LOCATION_ERROR:
      return {
        ...state,
        fetchUserLocationFailed: true,
        error: action.payload
      };
    case FETCHING_REVERSE_GEOCODE_LOCATION:
      return {
        ...state,
        fetchingReverseGeocodeLocation: action.payload
      };
    case FETCH_REVERSE_GEOCODE_LOCATION_SUCCESS:
      return state;
    case FETCH_REVERSE_GEOCODE_LOCATION_ERROR:
      return state;
    case SET_REVERSE_GEOCODE_LOCATION:
      return {
        ...state,
        reverseGeocodeLocation: action.payload
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    default:
      return state;
  }
}
