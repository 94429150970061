import React from 'react';
import { FormControl, FormLabel, Select, MenuItem, InputBase } from '@material-ui/core';
import shortid from 'shortid';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './FormField.styles';

const FormField = (props) => {
  const classes = useStyles();

  const getFieldByType = () => {
    switch (props.type) {
      case 'text':
        return (
          <InputBase
            className={classes.field}
            classes={{ input: classes.input }}
            fullWidth
            size="small"
            type="text"
            name={props.name}
            placeholder={props.placeholder}
            onChange={(e) => props.onChangeHandler(e)}
            onKeyDown={props.handleKeyDown}
          />
        );
      case 'text-multiline':
        return (
          <InputBase
            className={classes.field}
            classes={{ input: classes.input }}
            fullWidth
            multiline
            size="small"
            type="text"
            rows={props.rows}
            name={props.name}
            placeholder={props.placeholder}
            onChange={(e) => props.onChangeHandler(e)}
            onKeyDown={props.handleKeyDown}
          />
        );
      case 'select':
        return (
          <Select
            className={classes.field}
            disableUnderline
            displayEmpty
            name={props.name}
            value={props.value}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            onChange={(e) => props.onChangeHandler(e)}
            onKeyDown={props.handleKeyDown}
          >
            <MenuItem value="">Select</MenuItem>
            {props.options.map((option) => (
              <MenuItem key={shortid()} className={classes.menuItem} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return (
          <InputBase
            className={classes.field}
            classes={{ input: classes.input }}
            fullWidth
            size="small"
            type="text"
            name={props.name}
            placeholder={props.placeholder}
            onChange={(e) => props.onChangeHandler(e)}
            onKeyDown={props.handleKeyDown}
          />
        );
    }
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        className={classnames({
          [classes.label]: true,
          [classes.labelDark]: props.labelColor === 'dark',
          [classes.labelLight]: props.labelColor === 'light'
        })}
        classes={{
          root: props.labelColor === 'light' ? classes.labelRootLight : classes.labelRootDark,
          focused: props.labelColor === 'light' ? classes.labelFocusedLight : classes.labelFocusedDark
        }}
        required={props.required}
      >
        {props.label}
      </FormLabel>
      {getFieldByType()}
    </FormControl>
  );
};

FormField.propTypes = {
  type: PropTypes.oneOf(['text', 'text-multiline', 'select']).isRequired,
  label: PropTypes.string,
  labelColor: PropTypes.oneOf(['dark', 'light']).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func
};

export default FormField;
