import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  locationInputContainer: {
    marginTop: '1rem'
  },
  locationErrorMessage: {
    color: '#0C2224',
    lineHeight: '1.5625rem',
    letterSpacing: '0.29px'
  }
}));

export default useStyles;
