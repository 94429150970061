const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toast: {
    position: 'fixed',
    fontWeight: 700,
    lineHeight: '1.063rem',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    zIndex: 9999,
    [theme.breakpoints.up('md')]: {
      top: 115,
      fontSize: '0.875rem'
    },
    [theme.breakpoints.down('sm')]: {
      top: 165,
      fontSize: '0.75rem'
    }
  },
  toastSuccess: {
    color: '#ffffff',
    backgroundColor: '#0091FF'
  },
  toastError: {
    color: '#002134',
    backgroundColor: '#FCB400'
  }
}));

export default useStyles;
