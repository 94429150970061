import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Container, useTheme, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CancelOutlined } from '@material-ui/icons';
import shortid from 'shortid';
import classnames from 'classnames';
import { TOAST_TYPES } from '../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { deleteToast } from '../../redux/modules/toast';
import useStyles from './Toast.styles';

const Toast = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const allToasts = useSelector((state) => state.toast.toasts);
  const [toasts, setToasts] = useState(allToasts);

  const handleToastClose = (id) => {
    dispatch(deleteToast(id));
  };

  useEffect(() => {
    setToasts(allToasts);
  }, [allToasts]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length) {
        handleToastClose(toasts[0].id);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [toasts]);

  return (
    <div className={classes.root}>
      <Container className={isExtraSmall ? classes.toast : ''}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            {toasts.map((t) => (
              <Alert
                key={shortid()}
                className={classnames({
                  [classes.toast]: !isExtraSmall,
                  [classes.toastSuccess]: t.type === TOAST_TYPES.SUCCESS,
                  [classes.toastError]: t.type === TOAST_TYPES.ERROR
                })}
                variant="filled"
                icon={false}
                action={
                  <IconButton aria-label="close" color="inherit" size="small" onClick={() => handleToastClose(t.id)}>
                    <CancelOutlined fontSize="inherit" />
                  </IconButton>
                }
              >
                {t.message}
              </Alert>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Toast;
